import Index from "views/Index.js";
import Awards from "views/Awards";
// import News from "views/News";
import Trash from "views/Trash";
import Events from "views/Events";

const routes = [
  {
    path: "/index",
    name: "Our Space",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin",
  },
  {
    path: "/events",
    name: "Events",
    icon: "ni ni-calendar-grid-58 text-yellow",
    component: Events,
    layout: "/admin",
  },
  {
    path: "/awards",
    name: "Awards",
    icon: "ni ni-trophy text-blue",
    component: Awards,
    layout: "/admin",
  },
  // {
  //   path: "/news",
  //   name: "News Letter",
  //   icon: "ni ni-single-copy-04 text-warning",
  //   component: News,
  //   layout: "/admin",
  // },
  {
    path: "/trash",
    name: "Trash",
    icon: "ni ni-basket text-info",
    component: Trash,
    layout: "/admin",
  },
];
export default routes;
