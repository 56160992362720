/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Navbar,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  postEvents,
  putEvents,
  deleteEvents,
  getEvents,
} from "../utils/firebase";

const Events = () => {
  const [upcoming, setUpcoming] = useState([]);
  const [previous, setPrevious] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chapter, setChapter] = useState("");
  const [showEvent, setShowEvent] = useState(false);
  const [eventEdit, setEventEdit] = useState(false);
  const [currentEvent, setCurrentEvent] = useState({});
  const [description, setDescription] = useState("");
  const [eventName, setEventName] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [eventLink, setEventLink] = useState("");
  const [eventImage, setEventImage] = useState("");
  const [eventChapter, setEventChapter] = useState("CORE");
  const [eventDraft, setEventDraft] = useState(false);
  const [eventSchedule, setEventSchedule] = useState("");

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  useEffect(() => {
    setLoading(true);
    getEvents({
      chapter: chapter,
    })
      .then((data) => {
        if (data.status === "true") {
          setUpcoming(
            data.upcoming.map((e) => {
              const d = new Date(Number(e.date));
              return {
                ...e,
                new_date:
                  d.getDate() +
                  "/" +
                  (d.getMonth() + 1) +
                  "/" +
                  d.getFullYear(),
              };
            })
          );
          setPrevious(
            data.previous.map((e) => {
              const d = new Date(Number(e.date));
              return {
                ...e,
                new_date:
                  d.getDate() +
                  "/" +
                  (d.getMonth() + 1) +
                  "/" +
                  d.getFullYear(),
              };
            })
          );
          setLoading(false);
        } else {
          alert(data.status);
        }
      })
      .catch((e) => {
        console.log(e);
        alert("Something went wrong");
      });
  }, [chapter]);

  const addEvent = (e) => {
    e.preventDefault();
    if (eventName && description && eventDate) {
      if (eventEdit) {
        if (eventImage) {
          const fileReader = new FileReader();
          fileReader.onload = () => {
            const d = new Date(eventDate).getTime();
            const sd = new Date(eventSchedule || "").getTime();
            putEvents({
              oldevent: currentEvent.name,
              name: eventName,
              description: description,
              date: d,
              image: fileReader.result,
              link: eventLink,
              chapter: eventChapter,
              schedule: sd,
              draft: eventDraft,
            })
              .then((data) => {
                if (data.status === "true") {
                  setEventImage("");
                  setEventLink("");
                  setEventDate("");
                  setEventName("");
                  setDescription("");
                  setEventChapter("CORE");
                  setEventDraft(false);
                  setEventSchedule("");
                  setCurrentEvent("");
                  setEventEdit(false);
                  setShowEvent(false);
                  alert("Successfully created");
                  window.location.reload();
                } else {
                  alert(data.status);
                }
              })
              .catch((e) => {
                console.log(e);
                alert("Something went wrong");
              });
          };
          fileReader.readAsDataURL(eventImage);
        } else {
          const d = new Date(eventDate).getTime();
          const sd = new Date(eventSchedule).getTime();
          putEvents({
            oldevent: currentEvent.name,
            name: eventName,
            description: description,
            date: d,
            image: currentEvent.image,
            link: eventLink,
            chapter: eventChapter,
            schedule: sd,
            draft: eventDraft,
          })
            .then((data) => {
              if (data.status === "true") {
                setEventImage("");
                setEventLink("");
                setEventDate("");
                setEventName("");
                setDescription("");
                setEventChapter("CORE");
                setEventDraft(false);
                setEventSchedule("");
                setCurrentEvent("");
                setEventEdit(false);
                setShowEvent(false);
                alert("Successfully created");
                window.location.reload();
              } else {
                alert(data.status);
              }
            })
            .catch((e) => {
              console.log(e);
              alert("Something went wrong");
            });
        }
      } else {
        if (eventImage) {
          const fileReader = new FileReader();
          fileReader.onload = () => {
            const d = new Date(eventDate).getTime();
            const sd = new Date(eventSchedule).getTime();
            postEvents({
              name: eventName,
              description: description,
              date: d,
              image: fileReader.result,
              link: eventLink,
              chapter: eventChapter,
              schedule: sd,
              draft: eventDraft,
            })
              .then((data) => {
                if (data.status === "true") {
                  setEventImage("");
                  setEventLink("");
                  setEventDate("");
                  setEventName("");
                  setDescription("");
                  setEventChapter("CORE");
                  setEventDraft(false);
                  setEventSchedule("");
                  setCurrentEvent("");
                  setEventEdit(false);
                  setShowEvent(false);
                  alert("Successfully created");
                  window.location.reload();
                } else {
                  alert(data.status);
                }
              })
              .catch((e) => {
                console.log(e);
                alert("Something went wrong");
              });
          };
          fileReader.readAsDataURL(eventImage);
        } else {
          alert("Please fill required fields");
        }
      }
    } else {
      alert("Please fill required fields");
    }
  };

  const openEditEvent = (event) => {
    const appendLeadingZeroes = (n) => {
      if (n <= 9) {
        return "0" + n;
      }
      return n;
    };
    const d = new Date(Number(event.date));
    const sd = new Date(Number(event.schedule));
    setEventLink(event.link);
    setEventDate(
      d.getFullYear() +
        "-" +
        appendLeadingZeroes(d.getMonth() + 1) +
        "-" +
        appendLeadingZeroes(d.getDate()) +
        "T" +
        appendLeadingZeroes(d.getHours()) +
        ":" +
        appendLeadingZeroes(d.getMinutes())
    );
    setEventName(event.name);
    setDescription(event.description);
    setEventChapter(event.chapter);
    setEventDraft(event.draft);
    if (event.schedule) {
      setEventSchedule(
        sd.getFullYear() +
          "-" +
          appendLeadingZeroes(sd.getMonth() + 1) +
          "-" +
          appendLeadingZeroes(sd.getDate()) +
          "T" +
          appendLeadingZeroes(sd.getHours()) +
          ":" +
          appendLeadingZeroes(sd.getMinutes())
      );
    }
    setCurrentEvent(event);
    setEventEdit(true);
    setShowEvent(true);
  };

  const openEvent = (e) => {
    e.preventDefault();
    setShowEvent(true);
  };

  const closeEvent = (e) => {
    e.preventDefault();
    setEventImage("");
    setEventLink("");
    setEventDate("");
    setEventName("");
    setDescription("");
    setEventChapter("CORE");
    setEventDraft(false);
    setEventSchedule("");
    setCurrentEvent("");
    setEventEdit(false);
    setShowEvent(false);
  };

  const deleteEvent = (event) => {
    deleteEvents({
      name: event,
    })
      .then((data) => {
        if (data.status === "true") {
          alert("Successfully moved to trash");
          window.location.reload();
        } else {
          alert(data.status);
        }
      })
      .catch((e) => {
        console.log(e);
        alert("Something went wrong");
      });
  };

  const Upcoming = ({ event }) => {
    const [deleteUpcoming, setDeleteUpcoming] = useState(false);
    return (
      <Col lg="4" md="6" key={event.id} style={{ minWidth: "350px" }}>
        <Dropdown
          isOpen={deleteUpcoming}
          toggle={() => setDeleteUpcoming(!deleteUpcoming)}
        >
          <DropdownToggle style={{ marginLeft: "10px", padding: "5px 10px" }}>
            <i className="fa fa-ellipsis-v" />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => deleteEvent(event.name)}>
              Move To Trash
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <Card
          className="verify-card card-stats m-2 shadow"
          style={{
            cursor: "pointer",
            backgroundColor: event.draft ? "#dddddd" : "#ffffff",
          }}
          onClick={() => openEditEvent(event)}
        >
          <CardBody>
            <Row>
              <Col style={{ color: "#196aa1" }}>
                <span className="text-dark font-weight-bold">{event.name}</span>
                <br />
                <span>{event.new_date}</span>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    );
  };

  const Previous = ({ event }) => {
    const [deletePrevious, setDeletePrevious] = useState(false);
    return (
      <Col lg="4" md="6" key={event.id} style={{ minWidth: "350px" }}>
        <Dropdown
          isOpen={deletePrevious}
          toggle={() => setDeletePrevious(!deletePrevious)}
        >
          <DropdownToggle style={{ marginLeft: "10px", padding: "5px 10px" }}>
            <i className="fa fa-ellipsis-v" />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => deleteEvent(event.name)}>
              Move To Trash
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <Card
          className="verify-card card-stats m-2 shadow"
          style={{
            cursor: "pointer",
            backgroundColor: event.draft ? "#dddddd" : "#ffffff",
          }}
          onClick={() => openEditEvent(event)}
        >
          <CardBody>
            <Row>
              <Col style={{ color: "#196aa1" }}>
                <span className="text-dark font-weight-bold">{event.name}</span>
                <br />
                <span>{event.new_date}</span>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    );
  };

  return (
    <>
      {showEvent && (
        <div className="event-popup-wrap">
          <div className="event-popup shadow">
            <form>
              <h1>{eventEdit ? "Edit" : "Add"} event</h1>
              <div>
                <label>Event Name*</label>
                <Input
                  type="text"
                  value={eventName}
                  onChange={(e) => setEventName(e.target.value)}
                />
              </div>
              <div>
                <label>Event Description*</label>
                <ReactQuill
                  theme="snow"
                  value={description}
                  onChange={setDescription}
                  modules={modules}
                  style={{
                    height: "400px",
                    marginBottom: "90px",
                    fontFamily: "Arial",
                  }}
                />
              </div>
              <div>
                <label>Event Poster Release Schedule</label>
                <Input
                  type="datetime-local"
                  value={eventSchedule}
                  onChange={(e) => {
                    setEventSchedule(e.target.value);
                  }}
                />
              </div>
              <div>
                <label>Event Expiration Date*</label>
                <Input
                  type="datetime-local"
                  value={eventDate}
                  onChange={(e) => {
                    setEventDate(e.target.value);
                  }}
                />
              </div>
              <div>
                <label>Event Registration Link</label>
                <Input
                  type="text"
                  value={eventLink}
                  onChange={(e) => setEventLink(e.target.value)}
                />
              </div>
              <div>
                <label>Event Chapter</label>
                <Input
                  type="select"
                  value={eventChapter}
                  onChange={(e) => setEventChapter(e.target.value)}
                >
                  <option value="CORE">CORE</option>
                  <option value="CS">CS</option>
                  <option value="IAS">IAS</option>
                  <option value="WIE">WIE</option>
                  <option value="SIGHT">SIGHT</option>
                </Input>
              </div>
              <div>
                <label>Event Poster*</label>
                {eventEdit && (
                  <img
                    src={currentEvent.image}
                    style={{
                      width: "100%",
                      maxWidth: "300px",
                      display: "block",
                      marginBottom: "10px",
                    }}
                  />
                )}
                <Input
                  type="file"
                  onChange={(e) => setEventImage(e.target.files[0])}
                />
              </div>
              <div style={{ marginLeft: "5px" }}>
                <Input
                  type="checkbox"
                  checked={eventDraft}
                  onChange={(e) => setEventDraft(e.target.checked)}
                />
                <label>This is a draft</label>
              </div>
              <div className="event-popup-button">
                <Input type="button" value="Close" onClick={closeEvent} />
                <Input type="submit" value="Submit" onClick={addEvent} />
              </div>
            </form>
          </div>
        </div>
      )}
      <div
        className="header bg-gradient-info pb-8 pt-md-8 "
        style={{ minHeight: "100vh" }}
      >
        <Container fluid>
          <Navbar className="navbar-dark" id="navbar-main">
            <Form className="navbar-search navbar-search-dark form-inline">
              <FormGroup className="mb-0">
                <InputGroup className="input-group-alternative">
                  <Input
                    type="select"
                    value={chapter}
                    onChange={(e) => setChapter(e.target.value)}
                  >
                    <option value="">All</option>
                    <option value="CORE">CORE</option>
                    <option value="CS">CS</option>
                    <option value="IAS">IAS</option>
                    <option value="WIE">WIE</option>
                    <option value="SIGHT">SIGHT</option>
                  </Input>
                </InputGroup>
              </FormGroup>
            </Form>

            <Form className="navbar-search navbar-search-dark form-inline d-block d-md-flex ml-lg-auto">
              <FormGroup className="mb-0" onClick={openEvent}>
                <InputGroup className="input-group-alternative">
                  <Input
                    value="Add new event"
                    type="submit"
                    title="Add new event"
                  />
                </InputGroup>
              </FormGroup>
            </Form>
          </Navbar>
          <h1 style={{ color: "#ffffff", textAlign: "center" }}>
            Upcoming Events
          </h1>
          <Row className="justify-content-center">
            {loading ? (
              <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            ) : (
              upcoming.map((event) => <Upcoming event={event} key={event.id} />)
            )}
          </Row>
          <h1 style={{ color: "#ffffff", textAlign: "center" }}>
            Previous Events
          </h1>
          <Row className="justify-content-center">
            {loading ? (
              <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            ) : (
              previous.map((event) => <Previous event={event} key={event.id} />)
            )}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Events;
