/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Navbar,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import "react-quill/dist/quill.snow.css";
import {
  getTrash,
  deleteTrashAwards,
  deleteTrashEvents,
  deleteTrash,
  postEvents,
  postAwards,
} from "../utils/firebase";

const Trash = () => {
  const [events, setEvents] = useState([]);
  const [awards, setAwards] = useState([]);
  // const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chapter, setChapter] = useState("");

  useEffect(() => {
    setLoading(true);
    getTrash({
      chapter: chapter,
    })
      .then((data) => {
        if (data.status === "true") {
          setEvents(
            data.events.map((e) => {
              const d = new Date(Number(e.date));
              return {
                ...e,
                new_date:
                  d.getDate() +
                  "/" +
                  (d.getMonth() + 1) +
                  "/" +
                  d.getFullYear(),
              };
            })
          );
          setAwards(
            data.awards.map((e) => {
              const d = new Date(Number(e.date));
              return {
                ...e,
                new_date:
                  d.getDate() +
                  "/" +
                  (d.getMonth() + 1) +
                  "/" +
                  d.getFullYear(),
              };
            })
          );
          // setNews(
          //   data.news.map((e) => {
          //     const d = new Date(Number(e.date));
          //     return {
          //       ...e,
          //       new_date:
          //         d.getDate() +
          //         "/" +
          //         (d.getMonth() + 1) +
          //         "/" +
          //         d.getFullYear(),
          //     };
          //   })
          // );
          setLoading(false);
        } else {
          alert(data.status);
        }
      })
      .catch((e) => {
        console.log(e);
        alert("Something went wrong");
      });
  }, [chapter]);

  const deleteItem = (id, kind) => {
    (kind === "events" ? deleteTrashEvents : deleteTrashAwards)({
      id: id,
    })
      .then((data) => {
        if (data.status === "true") {
          alert("Successfully delete");
          window.location.reload();
        } else {
          alert(data.status);
        }
      })
      .catch((e) => {
        console.log(e);
        alert("Something went wrong");
      });
  };

  const deleteAll = () => {
    deleteTrash()
      .then((data) => {
        if (data.status === "true") {
          alert("Trash is now empty");
          window.location.reload();
        } else {
          alert(data.status);
        }
      })
      .catch((e) => {
        console.log(e);
        alert("Something went wrong");
      });
  };

  const restoreItem = async (event, kind) => {
    const blob = await fetch(event.image).then((r) => r.blob());
    const dataUrl = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
    (kind === "events" ? postEvents : postAwards)({
      ...event,
      image: dataUrl,
    })
      .then((data) => {
        if (data.status === "true") {
          (kind === "events" ? deleteTrashEvents : deleteTrashAwards)({
            id: event.id,
          })
            .then((data) => {
              if (data.status === "true") {
                alert("Successfully restored");
                window.location.reload();
              } else {
                alert(data.status);
              }
            })
            .catch((e) => {
              console.log(e);
              alert("Something went wrong");
            });
        } else {
          alert(data.status);
        }
      })
      .catch((e) => {
        console.log(e);
        alert("Something went wrong");
      });
  };

  const ItemCard = ({ event, kind }) => {
    const [deleteUpcoming, setDeleteUpcoming] = useState(false);
    return (
      <Col lg="4" md="6" key={event.id} style={{ minWidth: "350px" }}>
        <Dropdown
          isOpen={deleteUpcoming}
          toggle={() => setDeleteUpcoming(!deleteUpcoming)}
        >
          <DropdownToggle style={{ marginLeft: "10px", padding: "5px 10px" }}>
            <i className="fa fa-ellipsis-v" />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => restoreItem(event, kind)}>
              Restore
            </DropdownItem>
            <DropdownItem onClick={() => deleteItem(event.id, kind)}>
              Delete
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <Card
          className="verify-card card-stats m-2 shadow"
          style={{
            backgroundColor: event.draft ? "#dddddd" : "#ffffff",
          }}
        >
          <CardBody>
            <Row>
              <Col style={{ color: "#196aa1" }}>
                <span className="text-dark font-weight-bold">{event.name}</span>
                <br />
                <span>{event.new_date}</span>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    );
  };

  return (
    <>
      <div
        className="header bg-gradient-info pb-8 pt-md-8 "
        style={{ minHeight: "100vh" }}
      >
        <Container fluid>
          <Navbar className="navbar-dark" id="navbar-main">
            <Form className="navbar-search navbar-search-dark form-inline">
              <FormGroup className="mb-0">
                <InputGroup className="input-group-alternative">
                  <Input
                    type="select"
                    value={chapter}
                    onChange={(e) => setChapter(e.target.value)}
                  >
                    <option value="">All</option>
                    <option value="CORE">CORE</option>
                    <option value="CS">CS</option>
                    <option value="IAS">IAS</option>
                    <option value="WIE">WIE</option>
                    <option value="SIGHT">SIGHT</option>
                  </Input>
                </InputGroup>
              </FormGroup>
            </Form>

            <Form className="navbar-search navbar-search-dark form-inline d-block d-md-flex ml-lg-auto">
              <FormGroup className="mb-0" onClick={deleteAll}>
                <InputGroup className="input-group-alternative">
                  <Input
                    value="Empty Trash"
                    type="submit"
                    title="Empty Trash"
                  />
                </InputGroup>
              </FormGroup>
            </Form>
          </Navbar>
          <h1 style={{ color: "#ffffff", textAlign: "center" }}>Events</h1>
          <Row className="justify-content-center">
            {loading ? (
              <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            ) : (
              events.map((event) => (
                <ItemCard event={event} kind="events" key={event.id} />
              ))
            )}
          </Row>
          <h1 style={{ color: "#ffffff", textAlign: "center" }}>Awards</h1>
          <Row className="justify-content-center">
            {loading ? (
              <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            ) : (
              awards.map((event) => (
                <ItemCard event={event} kind="awards" key={event.id} />
              ))
            )}
          </Row>
          {/* <h1 style={{ color: "#ffffff", textAlign: "center" }}>News Letter</h1>
          <Row className="justify-content-center">
            {loading ? (
              <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            ) : (
              news.map((event) => (
                <ItemCard event={event} kind="news" key={event} />
              ))
            )}
          </Row> */}
        </Container>
      </div>
    </>
  );
};

export default Trash;
