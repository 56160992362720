import { initializeApp } from "firebase/app";
import {
  getStorage,
  ref,
  uploadString,
  getDownloadURL,
} from "firebase/storage";
import {
  getFirestore,
  collection,
  doc,
  addDoc,
  deleteDoc,
  updateDoc,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  getDoc,
} from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyC4HGcZfUnqzraPze-c5vFGm9IokKIvXNo",
  authDomain: "ieee-sb-gcek.firebaseapp.com",
  projectId: "ieee-sb-gcek",
  storageBucket: "ieee-sb-gcek.appspot.com",
  messagingSenderId: "994140595747",
  appId: "1:994140595747:web:8bffa9ddfc40af0050b396",
  measurementId: "G-S4NCE02XF0",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const storage = getStorage(firebaseApp);
const db = getFirestore(firebaseApp);

async function uploadFile(fileName, img) {
  try {
    const imgRef = ref(storage, "posters/" + fileName);
    const snapshot = await uploadString(imgRef, img, "data_url");
    const downloadURL = await getDownloadURL(snapshot.ref);

    return downloadURL;
  } catch (e) {
    console.log(e);
    return false;
  }
}

async function addOne(data, coll) {
  try {
    const docRef = await addDoc(collection(db, coll), data);
    console.log(docRef);

    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

async function findOne(coll, q) {
  try {
    const docRefs = await getDocs(query(collection(db, coll), q));
    if (docRefs.length > 0) {
      return { ...docRefs[0].data(), id: docRefs[0].id };
    } else {
      return false;
    }
  } catch (e) {
    console.log(e);
    return false;
  }
}

async function findAll(q) {
  try {
    const docRefs = await getDocs(q);
    const docs = [];
    docRefs.forEach((docRef) => {
      docs.push({ ...docRef.data(), id: docRef.id });
    });
    return docs;
  } catch (e) {
    console.log(e);
    return false;
  }
}

async function updateOne(data, id, coll) {
  try {
    await updateDoc(doc(db, coll, id), data);

    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

async function deleteOne(id, coll) {
  try {
    await deleteDoc(doc(db, coll, id));

    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

async function deleteAll(coll) {
  try {
    const docRefs = await getDocs(collection(db, coll));
    docRefs.forEach(async (docRef) => {
      await deleteDoc(doc(db, coll, docRef.id));
    });

    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

async function currentTime() {
  const res = await fetch("https://worldtimeapi.org/api/timezone/Asia/Kolkata");
  const data = await res.json();
  console.log(data?.unixtime);
  return data?.unixtime * 1000;
}

export async function login(data) {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      data.email,
      data.pass
    );
    const user = userCredential.user;
    return { ...user, status: "true" };
  } catch (e) {
    console.log(e);
    return { status: "Credentials not found" };
  }
}

// app.post("/api/change", async (req, res) => {
//   try {
//     const result = await findOne("users", where("email", "==", data.email));
//     if (result) {
//       const isMatch = await bcrypt.compare(data.oldpass, result.pass);
//       if (isMatch) {
//         const pass = await bcrypt.hash(data.pass, 10);
//         await updateOne({ pass: pass }, result.id, "users");
//         return { status: "true" };
//       } else {
//         return { status: "Credentials not found" };
//       }
//     } else {
//       return { status: "Credentials not found" };
//     }
//   } catch (e) {
//     console.log(e);
//     return { status: "Something went wrong" };
//   }
// });

export async function postEvents(data) {
  try {
    const find = await findOne("events", where("name", "==", data.name));
    if (find) {
      return { status: "Event name already taken" };
    } else {
      const regex = /^data:.+\/(.+);base64,(.*)$/;
      const matches = data.image.match(regex);
      const ext = matches[1];
      const t = await currentTime();
      const result = await uploadFile(data.name + t + "." + ext, data.image);
      if (result) {
        await addOne(
          {
            name: data.name,
            description: data.description,
            date: data.date,
            image: result,
            link: data.link,
            chapter: data.chapter,
            schedule: data.schedule,
            draft: data.draft,
          },
          "events"
        );
        return { status: "true" };
      } else {
        return { status: "Something went wrong" };
      }
    }
  } catch (e) {
    console.log(e);
    return { status: "Something went wrong" };
  }
}

export async function putEvents(data) {
  try {
    const find = await findOne("events", where("name", "==", data.oldevent));
    if (find && data.oldevent !== data.name) {
      return { status: "Event name already taken" };
    } else {
      let result;
      if (data.image[0] !== "h") {
        const regex = /^data:.+\/(.+);base64,(.*)$/;
        const matches = data.image.match(regex);
        const ext = matches[1];
        const t = await currentTime();
        result = await uploadFile(data.name + t + "." + ext, data.image);
      }

      await updateOne(
        {
          name: data.name,
          description: data.description,
          date: data.date,
          image: data.image[0] !== "h" ? result : data.image,
          link: data.link,
          chapter: data.chapter,
          schedule: data.schedule,
          draft: data.draft,
        },
        find.id,
        "events"
      );
      return { status: "true" };
    }
  } catch (e) {
    console.log(e);
    return { status: "Something went wrong" };
  }
}

export async function deleteEvents(data) {
  try {
    const event = await findOne("events", where("name", "==", data.name));
    await addOne({ ...event, id: null }, "trash-events");
    await deleteOne(event.id, "events");
    return { status: "true" };
  } catch (e) {
    console.log(e);
    return { status: "Something went wrong" };
  }
}

export async function deleteTrashEvents(data) {
  try {
    await deleteOne(data.id, "trash-events");
    return { status: "true" };
  } catch (e) {
    console.log(e);
    return { status: "Something went wrong" };
  }
}

export async function getEvents(data) {
  try {
    const date = await currentTime();
    let upcoming;
    if (data.chapter) {
      upcoming = await findAll(
        query(
          collection(db, "events"),
          where("date", ">=", date),
          where("chapter", "==", data.chapter),
          orderBy("date", "desc")
        )
      );
    } else {
      upcoming = await findAll(
        query(
          collection(db, "events"),
          where("date", ">=", date),
          orderBy("date", "desc")
        )
      );
    }

    let previous;
    if (data.chapter) {
      previous = await findAll(
        query(
          collection(db, "events"),
          where("date", "<", date),
          where("chapter", "==", data.chapter),
          orderBy("date", "desc"),
          limit(20)
        )
      );
    } else {
      previous = await findAll(
        query(
          collection(db, "events"),
          where("date", "<", date),
          orderBy("date", "desc"),
          limit(20)
        )
      );
    }

    return {
      status: "true",
      upcoming: data.skip ? [] : upcoming,
      previous: previous,
    };
  } catch (e) {
    console.log(e);
    return { status: "Something went wrong" };
  }
}

export async function postAwards(data) {
  try {
    const regex = /^data:.+\/(.+);base64,(.*)$/;
    const matches = data.image.match(regex);
    const ext = matches[1];
    const t = await currentTime();
    const result = await uploadFile(data.name + t + "." + ext, data.image);
    if (result) {
      await addOne(
        {
          name: data.name,
          description: data.description,
          date: data.date,
          image: result,
          chapter: data.chapter,
          schedule: data.schedule,
          draft: data.draft,
        },
        "awards"
      );
      return { status: "true" };
    } else {
      return { status: "Something went wrong" };
    }
  } catch (e) {
    return { status: "Something went wrong" };
  }
}

export async function putAwards(data) {
  try {
    let result;
    if (data.image[0] !== "h") {
      const regex = /^data:.+\/(.+);base64,(.*)$/;
      const matches = data.image.match(regex);
      const ext = matches[1];
      const t = await currentTime();
      result = await uploadFile(data.name + t + "." + ext, data.image);
    }

    await updateOne(
      {
        name: data.name,
        description: data.description,
        date: data.date,
        image: data.image[0] !== "h" ? result : data.image,
        chapter: data.chapter,
        schedule: data.schedule,
        draft: data.draft,
      },
      data.oldaward,
      "awards"
    );

    return { status: "true" };
  } catch (e) {
    console.log(e);
    return { status: "Something went wrong" };
  }
}

export async function deleteAwards(data) {
  try {
    const award = await getDoc(doc(db, "awards", data.id));
    await addOne({ ...award.data() }, "trash-awards");
    await deleteOne(data.id, "awards");
    return { status: "true" };
  } catch (e) {
    console.log(e);
    return { status: "Something went wrong" };
  }
}

export async function deleteTrashAwards(data) {
  try {
    await deleteOne(data.id, "trash-awards");
    return { status: "true" };
  } catch (e) {
    console.log(e);
    return { status: "Something went wrong" };
  }
}

export async function getAwards(data) {
  try {
    let awards;
    if (data.chapter) {
      awards = await findAll(
        query(
          collection(db, "awards"),
          where("chapter", "==", data.chapter),
          orderBy("date", "desc")
        )
      );
    } else {
      awards = await findAll(
        query(collection(db, "awards"), orderBy("date", "desc"))
      );
    }

    return {
      status: "true",
      awards: awards,
    };
  } catch (e) {
    console.log(e);
    return { status: "Something went wrong" };
  }
}

export async function getTrash(data) {
  try {
    let events;
    if (data.chapter) {
      events = await findAll(
        query(
          collection(db, "trash-events"),
          where("chapter", "==", data.chapter)
        )
      );
    } else {
      events = await findAll(query(collection(db, "trash-events")));
    }

    let awards;
    if (data.chapter) {
      awards = await findAll(
        query(
          collection(db, "trash-awards"),
          where("chapter", "==", data.chapter)
        )
      );
    } else {
      awards = await findAll(query(collection(db, "trash-awards")));
    }

    return {
      status: "true",
      events: events,
      awards: awards,
    };
  } catch (e) {
    console.log(e);
    return { status: "Something went wrong" };
  }
}

export async function deleteTrash() {
  try {
    await deleteAll("trash-awards");
    await deleteAll("trash-events");

    return {
      status: "true",
    };
  } catch (e) {
    console.log(e);
    return { status: "Something went wrong" };
  }
}
