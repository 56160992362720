/* eslint-disable react/prop-types */
import React, { useState } from "react";
import {
  Container,
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
import "react-quill/dist/quill.snow.css";
import { addEmitHelpers } from "typescript";

const Index = () => {
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmNewPass, setConfirmNewPass] = useState("");

  const changePass = () => {
    if (oldPass && newPass && confirmNewPass) {
      if (newPass === confirmNewPass) {
        // axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/change`, {
        //   oldpass: oldPass,
        //   pass: newPass,
        //   email: window.sessionStorage.getItem("mail"),
        // });
      } else {
        alert("Both new password and confirm new password field are not same");
      }
    } else {
      addEmitHelpers("All fields are required");
    }
  };

  return (
    <>
      <div
        className="header bg-gradient-info pb-8 pt-md-8 "
        style={{ minHeight: "100vh" }}
      >
        <Container fluid>
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <h2>Change Password</h2>
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Old Password"
                      type="password"
                      autoComplete="old-password"
                      onChange={(e) => setOldPass(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="New Password"
                      type="password"
                      autoComplete="new-password"
                      onChange={(e) => setNewPass(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Confirm New Password"
                      type="password"
                      autoComplete="new-password"
                      onChange={(e) => setConfirmNewPass(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    onClick={changePass}
                    disabled
                  >
                    Sign in
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default Index;
