/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Navbar,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  postAwards,
  putAwards,
  deleteAwards,
  getAwards,
} from "../utils/firebase";

const Awards = () => {
  const [awards, setAwards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chapter, setChapter] = useState("");
  const [showAward, setShowAward] = useState(false);
  const [awardEdit, setAwardEdit] = useState(false);
  const [currentAward, setCurrentAward] = useState({});
  const [description, setDescription] = useState("");
  const [awardName, setAwardName] = useState("");
  const [awardDate, setAwardDate] = useState("");
  const [awardImage, setAwardImage] = useState("");
  const [awardChapter, setAwardChapter] = useState("CORE");
  const [eventDraft, setEventDraft] = useState(false);
  const [eventSchedule, setEventSchedule] = useState("");

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  useEffect(() => {
    setLoading(true);
    getAwards({
      chapter: chapter,
    })
      .then((data) => {
        if (data.status === "true") {
          setAwards(
            data.awards.map((e) => {
              const d = new Date(Number(e.date));
              return {
                ...e,
                new_date:
                  d.getDate() +
                  "/" +
                  (d.getMonth() + 1) +
                  "/" +
                  d.getFullYear(),
              };
            })
          );
          setLoading(false);
        } else {
          alert(data.status);
        }
      })
      .catch((e) => {
        console.log(e);
        alert("Something went wrong");
      });
  }, [chapter]);

  const addAward = (e) => {
    e.preventDefault();
    if (awardName && description && awardDate) {
      if (awardEdit) {
        if (awardImage) {
          const fileReader = new FileReader();
          fileReader.onload = () => {
            const d = new Date(awardDate).getTime();
            const sd = new Date(eventSchedule || "").getTime();
            putAwards({
              oldaward: currentAward.id,
              name: awardName,
              description: description,
              date: d,
              image: fileReader.result,
              chapter: awardChapter,
              schedule: sd,
              draft: eventDraft,
            })
              .then((data) => {
                if (data.status === "true") {
                  setAwardImage("");
                  setAwardDate("");
                  setAwardName("");
                  setDescription("");
                  setAwardChapter("CORE");
                  setEventDraft(false);
                  setEventSchedule("");
                  setCurrentAward("");
                  setAwardEdit(false);
                  setShowAward(false);
                  alert("Successfully created");
                  // window.location.reload();
                } else {
                  alert(data.status);
                }
              })
              .catch((e) => {
                console.log(e);
                alert("Something went wrong");
              });
          };
          fileReader.readAsDataURL(awardImage);
        } else {
          const d = new Date(awardDate).getTime();
          const sd = new Date(eventSchedule || "").getTime();
          putAwards({
            oldaward: currentAward.id,
            name: awardName,
            description: description,
            date: d,
            image: currentAward.image,
            chapter: awardChapter,
            schedule: sd,
            draft: eventDraft,
          })
            .then((data) => {
              if (data.status === "true") {
                setAwardImage("");
                setAwardDate("");
                setAwardName("");
                setDescription("");
                setAwardChapter("CORE");
                setEventDraft(false);
                setEventSchedule("");
                setCurrentAward("");
                setAwardEdit(false);
                setShowAward(false);
                alert("Successfully created");
                // window.location.reload();
              } else {
                alert(data.status);
              }
            })
            .catch((e) => {
              console.log(e);
              alert("Something went wrong");
            });
        }
      } else {
        if (awardImage) {
          const fileReader = new FileReader();
          fileReader.onload = () => {
            const d = new Date(awardDate).getTime();
            const sd = new Date(eventSchedule || "").getTime();
            postAwards({
              name: awardName,
              description: description,
              date: d,
              image: fileReader.result,
              chapter: awardChapter,
              schedule: sd,
              draft: eventDraft,
              email: window.sessionStorage.getItem("mail"),
              pass: window.sessionStorage.getItem("api"),
            })
              .then((data) => {
                if (data.status === "true") {
                  setAwardImage("");
                  setAwardDate("");
                  setAwardName("");
                  setDescription("");
                  setAwardChapter("CORE");
                  setEventDraft(false);
                  setEventSchedule("");
                  setCurrentAward("");
                  setAwardEdit(false);
                  setShowAward(false);
                  alert("Successfully created");
                  window.location.reload();
                } else {
                  alert(data.status);
                }
              })
              .catch((e) => {
                console.log(e);
                alert("Something went wrong");
              });
          };
          fileReader.readAsDataURL(awardImage);
        } else {
          alert("Please fill required fields");
        }
      }
    } else {
      alert("Please fill required fields");
    }
  };

  const openEditAward = (award) => {
    const appendLeadingZeroes = (n) => {
      if (n <= 9) {
        return "0" + n;
      }
      return n;
    };
    const d = new Date(Number(award.date));
    const sd = new Date(Number(award.schedule));
    setAwardDate(
      d.getFullYear() +
        "-" +
        appendLeadingZeroes(d.getMonth() + 1) +
        "-" +
        appendLeadingZeroes(d.getDate()) +
        "T" +
        appendLeadingZeroes(d.getHours()) +
        ":" +
        appendLeadingZeroes(d.getMinutes())
    );
    console.log(
      d.getFullYear() +
        "-" +
        appendLeadingZeroes(d.getMonth() + 1) +
        "-" +
        appendLeadingZeroes(d.getDate()) +
        "T" +
        appendLeadingZeroes(d.getHours()) +
        ":" +
        appendLeadingZeroes(d.getMinutes())
    );
    setAwardName(award.name);
    setDescription(award.description);
    setAwardChapter(award.chapter);
    setEventDraft(award.draft);
    if (award.schedule) {
      setEventSchedule(
        sd.getFullYear() +
          "-" +
          appendLeadingZeroes(sd.getMonth() + 1) +
          "-" +
          appendLeadingZeroes(sd.getDate()) +
          "T" +
          appendLeadingZeroes(sd.getHours()) +
          ":" +
          appendLeadingZeroes(sd.getMinutes())
      );
    }
    setCurrentAward(award);
    setAwardEdit(true);
    setShowAward(true);
  };

  const openAward = (e) => {
    e.preventDefault();
    setShowAward(true);
  };

  const closeAward = (e) => {
    e.preventDefault();
    setAwardImage("");
    setAwardDate("");
    setAwardName("");
    setDescription("");
    setAwardChapter("CORE");
    setEventDraft(false);
    setEventSchedule("");
    setCurrentAward("");
    setAwardEdit(false);
    setShowAward(false);
  };

  const deleteAward = (award) => {
    deleteAwards({
      id: award,
    })
      .then((data) => {
        if (data.status === "true") {
          alert("Successfully moved to trash");
          window.location.reload();
        } else {
          alert(data.status);
        }
      })
      .catch((e) => {
        console.log(e);
        alert("Something went wrong");
      });
  };

  const Award = ({ award }) => {
    const [deletePrevious, setDeletePrevious] = useState(false);
    return (
      <Col lg="4" md="6" key={award.id} style={{ minWidth: "350px" }}>
        <Dropdown
          isOpen={deletePrevious}
          toggle={() => setDeletePrevious(!deletePrevious)}
        >
          <DropdownToggle style={{ marginLeft: "10px", padding: "5px 10px" }}>
            <i className="fa fa-ellipsis-v" />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => deleteAward(award.id)}>
              Move To Trash
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <Card
          className="verify-card card-stats m-2"
          style={{
            cursor: "pointer",
            backgroundColor: award.draft ? "#dddddd" : "#ffffff",
          }}
          onClick={() => openEditAward(award)}
        >
          <CardBody>
            <Row>
              <Col style={{ color: "#196aa1" }}>
                <span className="text-dark font-weight-bold">{award.name}</span>
                <br />
                <span>{award.new_date}</span>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    );
  };

  return (
    <>
      {showAward && (
        <div className="event-popup-wrap">
          <div className="event-popup shadow">
            <form>
              <h1>{awardEdit ? "Edit" : "Add"} award</h1>
              <div>
                <label>Award Name*</label>
                <Input
                  type="text"
                  value={awardName}
                  onChange={(e) => setAwardName(e.target.value)}
                />
              </div>
              <div>
                <label>Award Description*</label>
                <ReactQuill
                  theme="snow"
                  value={description}
                  onChange={setDescription}
                  modules={modules}
                  style={{
                    height: "400px",
                    marginBottom: "90px",
                    fontFamily: "Arial",
                  }}
                />
              </div>
              <div>
                <label>Award Poster Release Schedule</label>
                <Input
                  type="datetime-local"
                  value={eventSchedule}
                  onChange={(e) => {
                    setEventSchedule(e.target.value);
                  }}
                />
              </div>
              <div>
                <label>Award Date*</label>
                <Input
                  type="datetime-local"
                  value={awardDate}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setAwardDate(e.target.value);
                  }}
                />
              </div>
              <div>
                <label>Award Chapter</label>
                <Input
                  type="select"
                  value={awardChapter}
                  onChange={(e) => setAwardChapter(e.target.value)}
                >
                  <option value="CORE">CORE</option>
                  <option value="CS">CS</option>
                  <option value="IAS">IAS</option>
                  <option value="WIE">WIE</option>
                  <option value="SIGHT">SIGHT</option>
                </Input>
              </div>
              <div>
                <label>Award Poster*</label>
                {awardEdit && (
                  <img
                    src={currentAward.image}
                    style={{
                      width: "100%",
                      maxWidth: "300px",
                      display: "block",
                      marginBottom: "10px",
                    }}
                  />
                )}
                <Input
                  type="file"
                  onChange={(e) => setAwardImage(e.target.files[0])}
                />
              </div>
              <div style={{ marginLeft: "5px" }}>
                <Input
                  type="checkbox"
                  checked={eventDraft}
                  onChange={(e) => setEventDraft(e.target.checked)}
                />
                <label>This is a draft</label>
              </div>
              <div className="event-popup-button">
                <Input type="button" value="Close" onClick={closeAward} />
                <Input type="submit" value="Submit" onClick={addAward} />
              </div>
            </form>
          </div>
        </div>
      )}
      <div
        className="header bg-gradient-info pb-8 pt-md-8 "
        style={{ minHeight: "100vh" }}
      >
        <Container fluid>
          <Navbar className="navbar-dark" id="navbar-main">
            <Form className="navbar-search navbar-search-dark form-inline">
              <FormGroup className="mb-0">
                <InputGroup className="input-group-alternative">
                  <Input
                    type="select"
                    value={chapter}
                    onChange={(e) => setChapter(e.target.value)}
                  >
                    <option value="">All</option>
                    <option value="CORE">CORE</option>
                    <option value="CS">CS</option>
                    <option value="IAS">IAS</option>
                    <option value="WIE">WIE</option>
                    <option value="SIGHT">SIGHT</option>
                  </Input>
                </InputGroup>
              </FormGroup>
            </Form>

            <Form className="navbar-search navbar-search-dark form-inline d-block d-md-flex ml-lg-auto">
              <FormGroup className="mb-0" onClick={openAward}>
                <InputGroup className="input-group-alternative">
                  <Input
                    value="Add new award"
                    type="submit"
                    title="Add new award"
                  />
                </InputGroup>
              </FormGroup>
            </Form>
          </Navbar>
          <Row className="justify-content-center">
            {loading ? (
              <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            ) : (
              awards.map((award) => <Award award={award} key={award.id} />)
            )}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Awards;
